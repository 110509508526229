<template>
  <div class="px-10" data-testid="site-data-extract-page">
    <Breadcrumbs />

    <v-row>
      <v-col sm="6" class="pt-0">
        <div class="mb-2">
          <span class="text-patient-no mr-2">TRIAL {{ userTrialNumber }}</span>
        </div>

        <h4 class="page-title" data-testid="trial-title">Reports</h4>
      </v-col>
    </v-row>

    <ReportCard
      site-no="-"
      :report-type="reportType.AUDIT"
      report-context="trial"
      :overall-selected="currentSelected"
      @active="setCurrentSelected"
      >Trial Audit trail report</ReportCard
    >

    <ReportCard
      site-no="-"
      :report-type="reportType.USER_ACCESS"
      report-context="trial"
      :overall-selected="currentSelected"
      @active="setCurrentSelected"
      >Trial user access report</ReportCard
    >
  </div>
</template>

<script>
import Breadcrumbs from '@/components/layout/Breadcrumbs'
import ReportCard from '@/components/reports/ReportCard'
import userGettersMixin from '@/components/mixins/store/userGettersMixin'
import reportService from '@/services/trial-report-service'
import reportType from '@/constants/reportType'

export default {
  name: 'Reports',
  mixins: [userGettersMixin],
  provide() {
    return {
      reportService,
    }
  },

  components: { ReportCard, Breadcrumbs },
  data() {
    return { currentSelected: '', reportType }
  },
  methods: {
    setCurrentSelected(report) {
      this.currentSelected = report
    },
  },
}
</script>

<style scoped></style>
