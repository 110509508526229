<template>
  <v-card elevation="1" class="my-5">
    <v-row
      align="center"
      class="px-4 head-pointer justify-end"
      @click="handleCardClick"
    >
      <v-col>
        <v-icon v-if="!isActive" color="rgb(0, 25, 101)"
          >mdi-radiobox-blank</v-icon
        >
        <v-icon v-else color="rgb(0, 25, 101)">mdi-radiobox-marked</v-icon>
        <strong class="ml-3"><slot /></strong>
      </v-col>

      <v-col v-if="reportRequestedToday" class="text-right">
        <div>
          <small>
            <strong
              ><v-icon>mdi-alert-outline</v-icon> Run report is not
              accessible</strong
            >
            <span class="text-no-wrap">
              Please wait 1 day before requesting a new report
            </span>
          </small>
        </div>
      </v-col>

      <v-col v-if="atLeastOnePatientRequired" class="text-right">
        <div>
          <small>
            <strong
              ><v-icon>mdi-alert-outline</v-icon> Run report is not
              accessible</strong
            >
            <span class="text-no-wrap">
              To request a report, at least one patient must exist.
            </span>
          </small>
        </div>
      </v-col>
      <v-col cols="2" class="text-right">
        <v-btn
          v-if="!isActive || !allowRequestReport"
          color="secondary"
          :disabled="true"
          rounded
          depressed
          >Run report</v-btn
        >
        <RequestReport
          v-else-if="allowRequestReport"
          @new-report-requested="pollForReportStatus"
          :trialNo="getTrialNumber"
          :siteNo="siteNo"
          :showPatientsCount="showPatientsCount"
          :report-type="reportType"
          :report-context="reportContext"
        />
      </v-col>
    </v-row>
    <v-data-table
      v-if="isActive && hasData"
      data-testid="report-data-table"
      :headers="headers"
      :items="items"
      @click:row="() => {}"
      class="report-list"
      :hide-default-header="!hasData"
      :class="{ 'no-data-table': !hasData && !isLoading }"
      :hide-default-footer="!hasData && !isLoading"
      :footer-props="{
        'items-per-page-options': itemsPerPageOptions,
      }"
    >
      <template #item="{ item }">
        <tr>
          <td class="text-no-wrap">
            <span class="pr-1">
              <v-icon small color="black">mdi-paperclip</v-icon></span
            >
            <span class="hidden-sm-and-down">{{ item.fileName }}</span>
            <span class="hidden-md-and-up"
              >..{{
                item.fileName.substring(
                  item.fileName.lastIndexOf('_') + 1,
                  item.fileName.length
                )
              }}</span
            >
          </td>
          <td>
            {{ item.fileSize ? item.fileSize : '-' }}
          </td>
          <td v-if="showPatientsCount">
            {{ item.noOfSubjectsIncluded ? item.noOfSubjectsIncluded : '-' }}
          </td>
          <td>
            <DateFormat
              :value="item.requestedAt.time"
              data-testid="item-requested-date"
            />
          </td>

          <td>
            <ReportStatus :item="item" />
          </td>
          <td>
            <template v-if="item.status === 'created'">
              <v-tooltip max-width="300" bottom v-if="!isDeviceSupported">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="secondary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    small
                    class="mr-2"
                    >mdi-information</v-icon
                  >
                </template>
                <span
                  ><strong>Download is not possible from an iPad.</strong>
                  Please change to a PC to download
                </span>
              </v-tooltip>

              <ReportDownload
                :disabled="!isDeviceSupported"
                :item="item"
                :siteNo="siteNo"
                :report-type="reportType"
                :report-context="reportContext"
                @report-downloaded="pollForReportStatus"
              />
            </template>
          </td>
          <td class="text-right pr-6">
            <ReportAuditDialog :item="item" />
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import loadingState from '@/constants/loadingState'
import DateFormat from '../DateFormat'
import itemsPerPageOptions from '@/constants/itemsPerPageOptionsPagination'

import Vue from 'vue'
import RequestReport from './RequestReport'
import generationStatus from '@/constants/generationStatus'
import trialSettingsGettersMixin from '@/components/mixins/store/trialSettingsGettersMixin'
import ReportAuditDialog from './ReportAuditDialog'
import ReportStatus from './ReportStatus'
import ReportDownload from './ReportDownload'
import globalProgressActionsMixins from '@/components/mixins/store/globalProgressActionsMixins'
import deviceInfo, { simulateIpad } from '@/utils/deviceInfo'
import userGettersMixin from '@/components/mixins/store/userGettersMixin'
import patientService from '@/services/site-patient-service'

export default {
  name: 'ReportCard',
  mixins: [
    trialSettingsGettersMixin,
    globalProgressActionsMixins,
    userGettersMixin,
  ],
  inject: { reportService: 'reportService' },
  components: {
    ReportDownload,
    ReportStatus,
    ReportAuditDialog,
    RequestReport,
    DateFormat,
  },
  props: {
    siteNo: { type: [String, Number], required: true },
    reportType: { type: String, required: true },
    overallSelected: { type: String, required: true },
    reportContext: { type: String, required: true },
    showPatientsCount: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      isDeviceSupported: false,
      itemsPerPageOptions,
      isActive: false,
      state: loadingState.INITIAL,
      items: [],
      hasPatients: false,
    }
  },
  watch: {
    overallSelected(newValue) {
      if (newValue && newValue !== this.reportType) {
        this.isActive = false
      }
    },
  },
  computed: {
    getTrialNumber() {
      if (this.trialNumber) {
        return this.trialNumber
      }

      return this.userTrialNumber
    },
    isLoading() {
      return this.state === loadingState.LOADING
    },
    hasData() {
      return this.items.length > 0
    },
    atLeastOnePatientRequired() {
      return (
        this.isActive &&
        !this.isLoading &&
        !this.reportRequestedToday &&
        this.showPatientsCount &&
        !this.hasPatients
      )
    },
    allowRequestReport() {
      return (
        !this.isLoading &&
        !this.reportRequestedToday &&
        (!this.showPatientsCount || this.hasPatients)
      )
    },
    reportRequestedToday() {
      if (this.siteNo !== '-') {
        return this.items.some(
          a =>
            !a.isSponsorReport &&
            new Date(a.requestedAt.time).getDate() === new Date().getDate()
        )
      }
      return this.items.some(
        a => new Date(a.requestedAt.time).getDate() === new Date().getDate()
      )
    },
    headers() {
      let list = [
        {
          text: 'File name',
          sortable: false,
        },
        {
          text: 'File size',
          value: 'fileSize',
          sortable: false,
          width: '80px',
        },
        { text: 'Patients', value: 'patients', sortable: false },
        {
          text: 'Request date',
          value: 'requestDate',
          sortable: false,
          width: '120px',
        },
        {
          text: 'Status',
          sortable: false,
        },
        { text: '', value: 'download', sortable: false },
        { text: '', value: 'audit', sortable: false },
      ]

      return this.showPatientsCount
        ? list
        : list.filter(item => item.value !== 'patients')
    },
  },
  methods: {
    fetchHasPatients() {
      this.loadingState = loadingState.LOADING
      this.globalStartLoadingRequested()
      patientService
        .getPatientList(this.siteNo)
        .then(response => {
          this.loadingState = loadingState.LOAD_SUCCEEDED
          this.hasPatients = !!response.data?.length
        })
        .catch(error => {
          this.loadingState = loadingState.LOAD_ERRORED
          this.hasPatients = false
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
        .finally(() => {
          this.globalStopLoadingRequested()
        })
    },
    pollForReportStatus() {
      this.reportService
        .getReports(this.reportType, this.siteNo)
        .then(response => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.items = response.reports
          if (this.isActive) {
            if (
              this.items.some(
                a =>
                  a.status === generationStatus.IN_PROGRESS ||
                  a.status === generationStatus.AWAITING_SUB_COMPONENTS ||
                  a.status === generationStatus.REQUESTED
              )
            ) {
              setTimeout(() => {
                this.pollForReportStatus()
              }, 30000)
            }
          }
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
        .finally(() => {
          this.globalStopLoadingRequested()
        })
    },
    handleCardClick() {
      this.isActive = !this.isActive
      if (this.isActive) {
        this.state = loadingState.LOADING
        this.globalStartLoadingRequested()
        if (this.showPatientsCount) this.fetchHasPatients()
        this.pollForReportStatus()
        this.$emit('active', this.reportType)
        this.isDeviceSupported = simulateIpad
          ? true
          : !deviceInfo.isIpadAir2019()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.head-pointer {
  cursor: pointer;
}

.report-list.v-data-table {
  ::v-deep {
    thead {
      th {
        background-color: $nn-LB_T98;
        height: 40px !important;
      }
    }
  }
}
</style>
