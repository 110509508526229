<template>
  <div>
    <v-chip
      v-if="downloadedAt"
      label
      rounded
      data-testid="report-status-downloaded"
      class="spacing-2"
      color="#DFEFEE"
      small
    >
      <v-icon left small color="#2A918B">mdi-check-circle</v-icon>
      Downloaded
      <DateFormat
        :value="item.firstDownloadedAt.time"
        format="dd MMM yyyy"
        class="pl-1 hidden-sm-and-down"
      />
    </v-chip>
    <v-chip
      v-else-if="readyForDownload"
      label
      data-testid="report-status-ready"
      class="spacing-2"
      color="#E2EFFA"
      small
    >
      Ready for download
    </v-chip>
    <v-chip
      v-else-if="inProgress"
      label
      data-testid="report-status-progress"
      class="spacing-2"
      color="#F7DD99"
      small
    >
      In progress
    </v-chip>
    <v-chip
      v-else-if="errored"
      label
      data-testid="report-status-error"
      class="spacing-2"
      color="#FCF2F5"
      small
    >
      Error
    </v-chip>
    <v-chip
      v-else-if="requested"
      label
      data-testid="report-status-error"
      class="spacing-2"
      color="#F7DD99"
      small
    >
      Requested
    </v-chip>
    <v-tooltip right v-if="errored">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="secondary"
          dark
          v-bind="attrs"
          v-on="on"
          small
          class="pl-1"
          >mdi-information</v-icon
        >
      </template>
      <span>Please contact help desk for<br />further assistance. </span>
    </v-tooltip>
  </div>
</template>

<script>
import DateFormat from '@/components/DateFormat'
import generationStatus from '@/constants/generationStatus'

export default {
  name: 'ReportStatus',
  components: { DateFormat },
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    downloadedAt() {
      return (
        this.item.status === generationStatus.CREATED &&
        this.item.firstDownloadedAt
      )
    },
    readyForDownload() {
      return (
        this.item.status === generationStatus.CREATED &&
        !this.item.firstDownloadedAt
      )
    },
    inProgress() {
      return (
        this.item.status === generationStatus.IN_PROGRESS ||
        this.item.status === generationStatus.AWAITING_SUB_COMPONENTS
      )
    },
    errored() {
      return this.item.status === generationStatus.ERROR
    },
    requested() {
      return this.item.status === generationStatus.REQUESTED
    },
  },
}
</script>
