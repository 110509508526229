import dateTimeWithTimeZone from '@/utils/date/dateTimeWithTimeZone'
import Vue from 'vue'

export default {
  requestReport(reportType: string) {
    return new Promise((resolve, reject) => {
      const currentClientTime = dateTimeWithTimeZone()

      Vue.$http
        .post(`/web/trial/report/${reportType}`, { currentClientTime })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('requestReport error', error, {
            currentClientTime,
          })
          reject(error)
        })
    })
  },
  getReports(reportType: string) {
    return new Promise((resolve, reject) => {
      const currentClientTime = dateTimeWithTimeZone()

      Vue.$http
        .get(`/web/trial/report/${reportType}`)
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getReports error', error, {
            currentClientTime,
          })
          reject(error)
        })
    })
  },
  downloadReport(reportType: string, reportId: number) {
    return new Promise((resolve, reject) => {
      const currentClientTime = dateTimeWithTimeZone()

      Vue.$http
        .post(
          `/web/trial/report/${reportType}/${reportId}/download`,
          { currentClientTime },
          { responseType: 'arraybuffer' }
        )
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('downloadReport error', error, {
            currentClientTime,
          })
          reject(error)
        })
    })
  },
}
